import React from 'react'
import DetailsSidebar from './ServicesSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const EcommerceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        {/* <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div> */}

                        <div className="services-details-desc pt-100">
                            <span className="sub-title">Digital Transformation</span>
                            <h3>Headless eCommerce Benefits</h3>
                            <p>Headless e-commerce is a type of e-commerce architecture that separates the front-end user interface from the back-end systems and services. This allows for greater flexibility and customization in the design and user experience, as well as better scalability and performance.</p>

                            <p>Headless e-commerce offers several key benefits to businesses, including:</p>
                            
                            <p><strong>Improved Performance:</strong> By separating the front-end UI from the back-end technology infrastructure, headless e-commerce websites can be optimized for performance. This is particularly important for businesses that need to handle large amounts of traffic or support complex user interactions.</p>

                            <p><strong>Increased Flexibility:</strong> Headless e-commerce architecture allows businesses to build custom user interfaces that meet the unique needs of their customers. This leads to increased flexibility and allows businesses to quickly respond to changes in customer needs or market trends.</p>

                            <p><strong>Better Integration:</strong> Headless e-commerce websites can be easily integrated with other technology systems, including CRM systems, marketing automation platforms, and payment gateways. This leads to improved efficiency and a better customer experience.</p>

                            <p><strong>Enhanced User Experience:</strong> By building custom user interfaces, headless e-commerce websites can deliver a personalized and seamless customer experience across all touchpoints, including mobile apps, websites, and physical stores.</p>
                           
                            <p><strong>Improved Scalability:</strong> Headless e-commerce architecture is designed to be scalable, allowing businesses to grow and expand without the need for extensive technical infrastructure.</p>

                            <p>To implement a headless e-commerce solution, businesses must first select a traditional e-commerce platform to manage their back-end technology infrastructure. This platform should be chosen based on the business's needs, including their budget, technical requirements, and desired features. The front-end UI should then be built using a JavaScript framework and integrated with the back-end platform using APIs.</p>

                            <p>Headless e-commerce is a powerful tool for businesses looking to improve their e-commerce website and deliver a better customer experience. By separating the front-end UI from the back-end technology infrastructure, businesses can build custom user interfaces that meet the unique needs of their customers, integrate with other technology systems, and scale as their business grows.</p>

                            {/* <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>We Offer </h3>
                                        <ul>
                                            <li>UI/UX Design</li>
                                            <li>Modular Content Management</li>
                                            <li>Front-End Development</li>
                                            <li>Back-end Development</li>
                                            <li>DevOps</li>
                                            <li>Analytics</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

                            {/* <h3>E-Commerce Solutions</h3> */}

                            {/* <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Headless eCommerce
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Adobe Magento 
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Shopify eCommerce
                                    </div>
                                </div>
            
                            </div> */}

                            {/* <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <DetailsSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EcommerceDetailsContent